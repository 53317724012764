import config from "config";
import { io, Socket } from "socket.io-client";
import { setWindow } from "utils/winUtils";
import AuthServices from "./AuthServices";
import Utils from "./Utils";

export interface IEventMessage {
  topic: string;
  data?: any;
  message: string;
}

export class SocketClientFrontend {
  static client: Socket;
  static isConnected: boolean = false;
  static roomsToJoin: { [key: string]: boolean } = {};
  static init() {
    if (!config.ROOT_URL || !AuthServices.getToken()) {
      console.log(` Downloader URL OR Auth token is not available.`);
      return null;
    }

    if (!this.client) {
      this.client = io(config.ROOT_URL, {
        transports: ["websocket"],
        query: {
          token: AuthServices.getToken(),
          frontend: true,
        },
      });

      this.client.on("connect", () => {
        console.log("Socket conneccted");
        this.isConnected = true;
        this.send("joinRoom", Object.keys(this.roomsToJoin));
      });

      this.client.on("disconnect", () => {
        console.log("Socket disconnected");
        this.isConnected = false;
      });

      this.client.on("error", (ex) => {
        console.log("Error in socket", ex);
        this.isConnected = false;
      });

      this.client.on("message", (data) => {
        Utils.fireEvent(`SOCKET_MESSAGE`, data);
      });
    }

    return this.client;
  }

  static send(message: string, data: any) {
    if (!this.isConnected) return;
    this.client.emit(message, data);
  }

  static sendComplete(message: string, data: any) {
    if (!this.isConnected) return;
    return new Promise((resolve, reject) => {
      this.client.emit(message, data, (resData: any) => {
        resolve(resData);
      });
      setTimeout(() => {
        reject(new Error(`Timeout after ${10000}`));
      }, 10000);
    });
  }

  static addListener(topics: string[], cb: (data: any) => void) {
    for (let t of topics) {
      this.roomsToJoin[t] = true;
    }
    function listener(event: any) {
      cb(event.detail.data);
    }
    document.addEventListener(`SOCKET_MESSAGE`, listener);
    if (this.isConnected) this.send("joinRoom", topics);
    return {
      unsubscribe: () => {
        document.removeEventListener(`SOCKET_MESSAGE`, listener);
        this.send("leaveRoom", topics);
      },
    };
  }
}

setWindow("SocketClientFrontend", SocketClientFrontend);
