import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ErrorPlaceHolder from "common/ErrorPlaceHolder";
import HTextField from "components/inputs/hookInputs/HTextField";
import config from "config";
import { useLogin } from "hooks/queries/auth";
import { useRouter } from "next/router";
import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import AuthServices from "services/AuthServices";
import { clearAllCaches } from "services/ReactQueryClient";
import { AuthStore, updateUserData } from "stores/authStore";
import { IFormInputs } from "types/user";
import * as yup from "yup";

const schema = yup
  .object({
    Username: yup.string().required("Username is Required"),
    Password: yup.string().required("Password is Required"),
  })
  .required();

export default function Login({ noRedirect }: { noRedirect?: boolean }) {
  const actionLogin = useLogin();
  const router = useRouter();

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: IFormInputs) => {
    AuthServices.removeToken();
    clearAllCaches();
    const loginResponse = await actionLogin.mutateAsync(data);

    AuthServices.setToken(loginResponse.token);

    updateUserData(loginResponse.tokenData);

    if (!noRedirect) {
      router.push("/");
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        lg={9}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random/?nature)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={4}
        lg={3}
        component={Paper}
        elevation={6}
        // square
      >
        <FormProvider {...methods}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar src="/images/logo.png" sx={{ width: 150, height: 150 }} />
            <Typography component="h1" variant="h5" sx={{ my: 2 }}>
              Login
            </Typography>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <HTextField
                      componentProps={{ label: "Username" }}
                      name="Username"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <HTextField
                      componentProps={{ label: "Password", type: "password" }}
                      name="Password"
                    />
                  </Grid>
                  {config.IS_DEVELOPMENT && (
                    <Grid item xs={12}>
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({ Username: "admin", Password: "44544545" });
                        }}
                      >
                        SA
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({
                            Username: "hemant",
                            Password: "44544545",
                          });
                        }}
                      >
                        OA
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={() => {
                          onSubmit({
                            Username: "master1",
                            Password: "44544545",
                          });
                        }}
                      >
                        MA
                      </Button>{" "}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      loading={actionLogin.isLoading}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Login
                    </LoadingButton>
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid> */}
                </Grid>
              </Box>
            </form>
          </Box>
          {actionLogin.isError && <ErrorPlaceHolder ex={actionLogin.error} />}
        </FormProvider>
      </Grid>
    </Grid>
  );
}
