import { IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LoggedInUserAvatar } from "common/custom/SystemUserAvatar";
import useIsMobile from "hooks/userIsMobile";
import { useRouter } from "next/router";
import * as React from "react";
import AuthServices from "services/AuthServices";

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isMobile = useIsMobile();
  const router = useRouter();

  return (
    <div>
      <IconButton size="large" edge="end" onClick={handleClick} color="inherit">
        <LoggedInUserAvatar withName={!isMobile} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            router.push("/profile");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem
          onClick={async () => {
            AuthServices.onLogout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
