import { Avatar, Typography } from "@mui/material";
import { CommonDateUtils } from "commonlib";
import { useAuth } from "contexts/AuthContext";
import { addSeconds } from "date-fns";
import React from "react";
import AuthServices from "services/AuthServices";
import { IUser } from "types/user";

export function LoggedInUserAvatar({ withName }: { withName?: boolean }) {
  const { user } = useAuth();

  let [serverDateTime, setServerDateTime] = React.useState<any>(
    (user as any)?.ServerDateTime
  );
  React.useEffect(() => {
    let timer = setInterval(() => {
      setServerDateTime((date: any) => {
        if (!date) return null;
        return CommonDateUtils.format(
          addSeconds(new Date(date), 1),
          "yyyy-MM-dd hh:mm:ss a"
        );
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {withName && (
        <Typography>
          {user?.FirstName} {user?.LastName}
          {/* Temp code to see server time */}
          {AuthServices.is("SYSTEM_ADMIN") && (
            <small>
              <br />
              {serverDateTime}
            </small>
          )}
        </Typography>
      )}
      &nbsp;
      <Avatar>{user ? getUserInitial(user) : undefined}</Avatar>
    </div>
  );
}

function getUserInitial(user: IUser) {
  return `${user.FirstName?.substring(0, 1) || ""}${
    user.LastName?.substring(0, 1) || ""
  }`;
}
