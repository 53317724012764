import { InputAdornment, TextField, Theme, useTheme } from "@mui/material";
import React, { ComponentProps } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getError } from "./formHelpers";

export default function HTextField(props: {
  name: string;
  componentProps: ComponentProps<typeof TextField>;
  changeColor?: any;
  numberOnly?: boolean;
  addCurrencyAdornment?: boolean;
  onPostChange?: (e: any) => void;
}) {
  const objForm = useFormContext();
  const theme = useTheme<Theme>();

  // const val = props.changeColor ? objForm.getValues(props.name) : 0;

  let value = objForm.getValues(props.name)?.toString() || "";
  const isZero = !value || value === "0";

  props.componentProps.InputProps = { ...props?.componentProps?.InputProps };
  if (props.addCurrencyAdornment) {
    props.componentProps.InputProps.startAdornment = (
      <InputAdornment position="start" className="revenueInputAdornment">
        $
      </InputAdornment>
    );
    props.componentProps.InputProps.className = "revenueInput";
  }

  return (
    <Controller
      control={objForm.control}
      name={props.name}
      render={(ctrl) => {
        return (
          <TextField
            inputProps={{ step: 0.01 }}
            helperText={getError(props.name, objForm)}
            error={!!getError(props.name, objForm)}
            onChange={(e) => {
              let val = e.target.value;

              /** Remove any strings from the number if `numberOnly` is true */
              if (props.numberOnly && val) val = val.replace(/[^0-9\.\-]+/g, "");

              objForm.setValue(ctrl.field.name, val);
              if (props.onPostChange) {
                props.onPostChange(e);
              }
            }}
            /** I need to do objForm.getValues because ctrl.field.value might have bug, but it was returning older value */
            value={objForm.getValues(props.name)?.toString() || ""}
            sx={
              props.changeColor
                ? {
                    input: {
                      height: "8px",
                      color: isZero ? "" : theme.palette.info.light,
                      fontWeight: isZero ? "none" : "bold",
                    },
                  }
                : {}
            }
            {...props.componentProps}
          />
        );
      }}
    />
  );
}
