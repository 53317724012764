import { Alert } from "@mui/material";
import { Box } from "@mui/system";
import Loading from "components/assets/Loading";
import Login from "components/login/Login";
import { useAuth } from "contexts/AuthContext";
import React from "react";
import LoadingSkeleton from "./LodingSkeleton";

export default function AuthGuard(props: {
  children: any;
  AdminOnly?: boolean;
}) {
  const auth = useAuth();
  if (!auth.isUserDataFetched) return <Loading />; // Loading
  if (auth.user?._id) {
    if (props.AdminOnly && !auth.user?.IsAdminUser) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Alert severity="error" sx={{ mt: 5 }}>
            This feature is only available to admin
          </Alert>
        </Box>
      );
    }
    return props.children;
  }

  // Not logged in.
  return (
    <div>
      <Login noRedirect={true} />
    </div>
  );
}
