import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Icon } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RIcon from "common/misc/RIcon";
import { useList } from "hooks/commonQuries";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  CommonStore,
  setDrawerWidth,
  setIsDrawerOpenOnMobile,
  setMenuCollapse,
} from "stores/commonStore";
import { IMenuItem } from "types/common";
import { isChildrenSelected, isItemSelected } from "./SidebarConfig";

export default function SideNavList() {
  const navControls: IMenuItem[] | undefined =
    useList<IMenuItem>("nav-list").data;
  if (!navControls) return null;
  return <MenuList lstMenu={navControls} depth={0} />;
}

export function MenuList({
  lstMenu,
  depth,
}: {
  lstMenu: IMenuItem[];
  depth: number;
}) {
  const router = useRouter();
  const { menuCollapseStatus, isDrawerMini } = CommonStore.useState(
    (store) => store
  );
  return (
    <>
      <List>
        {lstMenu &&
          lstMenu.map((item: IMenuItem, index: number) => {
            let isSelected =
              isItemSelected(item, router.asPath) ||
              isChildrenSelected(item, router.asPath);
            let isChildSelected = isChildrenSelected(item, router.asPath);
            let isExpanded =
              isChildSelected || menuCollapseStatus[index.toString()];
            return (
              <Link href={item.Path || "/"} key={index}>
                <ListItem
                  disablePadding
                  sx={{
                    display: "block",
                    pl: depth * (isDrawerMini ? 1 : 3),
                  }}
                  divider={index < lstMenu.length - 1}
                  component={item.Children ? "li" : "a"}
                  href={item.Path}
                  onClick={() => {
                    if (!item.Children) setDrawerWidth("mini");
                  }}
                >
                  <ListItemButton
                    disableRipple
                    sx={{}}
                    onClick={(e) => {
                      setIsDrawerOpenOnMobile(false);
                      if (item.Path) {
                        router.push(item.Path);
                      } else {
                        e.preventDefault();
                        setMenuCollapse(index.toString(), !isExpanded);
                      }
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      {item.Icon && (
                        <Icon color={isSelected ? "primary" : "inherit"}>
                          {item.Icon}
                        </Icon>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.Title}
                      sx={{
                        whiteSpace: "nowrap",
                        visibility: isDrawerMini ? "hidden" : "visible",
                        color: isSelected ? "var(--primary)" : "",
                      }}
                    />
                    {!isDrawerMini && (
                      <>
                        {item.Children && (
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: isExpanded ? 0 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {isExpanded ? (
                              <RIcon name="arrow_drop_down"></RIcon>
                            ) : (
                              <RIcon name="arrow_right"></RIcon>
                            )}
                          </ListItemIcon>
                        )}
                      </>
                    )}
                  </ListItemButton>

                  {item.Children && (
                    <Collapse in={isExpanded}>
                      <MenuList lstMenu={item.Children} depth={depth + 1} />
                    </Collapse>
                    // <CollapseMenu
                    //   isOpen={isExpanded && !isDrawerMini}
                    //   lstMenu={item.Children}
                    // />
                  )}
                </ListItem>
              </Link>
            );
          })}
      </List>
    </>
  );
}
