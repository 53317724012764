import { useMutation } from "react-query";
import ApiService from "services/ApiService";
import { IFormInputs } from "types/user";

export function useLogin(postExecution?: (data?: any) => void) {
  return useMutation(async (data: IFormInputs) => {
    const result = await ApiService.login(data);
    return result.data;
  });
}
