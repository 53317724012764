import React, { Component, ReactElement } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import RIcon from "./RIcon";

interface SeeMoreProps {
  text?: string;
  length?: number;
  replaceNewLineWithBR?: boolean;
  replaceNewLineOnSummary?: boolean;
  inModal?: boolean;
  modalTitle?: any;
  clickableLine?: boolean;
  onSeeMoreClick?: () => void;
}

export default class SeeMore extends Component<SeeMoreProps> {
  state = {
    isExtended: false,
  };
  render() {
    let text = this.props.text || "";
    let textLength =
      this.props.length || this.props.length === 0 ? this.props.length : 250;
    let textToDisplay = this.state.isExtended
      ? text
      : text.substr(0, textLength);

    if (
      this.props.replaceNewLineWithBR !== false &&
      !(!this.state.isExtended && this.props.replaceNewLineOnSummary !== true)
    ) {
      textToDisplay = textToDisplay.replace(/\n/g, "\n<br>");
    }
    let textComponent = (
      <Typography
        display="inline"
        variant="body2"
        style={{ textAlign: "justify" }}
      >
        <span dangerouslySetInnerHTML={{ __html: textToDisplay }}></span>
      </Typography>
    );
    if (text?.length < (this.props.length || 250)) return textComponent;

    if (!this.state.isExtended) {
      return (
        <span
          onClick={
            this.props.clickableLine
              ? (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.setState({ isExtended: !this.state.isExtended });
                }
              : undefined
          }
          style={{ cursor: this.props.clickableLine ? "pointer" : "" }}
        >
          {textComponent}
          <Typography
            display="inline"
            gutterBottom
            variant="body2"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (this.props.onSeeMoreClick) {
                this.props.onSeeMoreClick();
              } else {
                this.setState({ isExtended: !this.state.isExtended });
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {"... "}See More
          </Typography>
        </span>
      );
    } else {
      if (this.props.inModal) {
        return (
          <Dialog
            open={this.state.isExtended}
            onClose={() => {
              this.setState({ isExtended: false });
            }}
            maxWidth="lg"
          >
            <DialogTitle
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>{this.props.modalTitle}</div>
              <RIcon
                name="close"
                onClick={() => {
                  this.setState({ isExtended: false });
                }}
                style={{ cursor: "pointer" }}
              />
            </DialogTitle>
            <DialogContent>{textComponent}</DialogContent>
          </Dialog>
        );
      }
      return (
        <span>
          {textComponent}
          <Typography
            display="inline"
            gutterBottom
            variant="body2"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (this.props.onSeeMoreClick) {
                this.props.onSeeMoreClick();
              } else {
                this.setState({ isExtended: !this.state.isExtended });
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {" "}
            See Less
          </Typography>
        </span>
      );
    }
  }
}
