import {
  Badge,
  Box,
  Button,
  IconButton,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import RIcon from "common/misc/RIcon";
import SeeMore from "common/misc/SeeMore";
import { useAuth } from "contexts/AuthContext";
import { useList } from "hooks/commonQuries";
import useServerEvent from "hooks/useSSEEvent";
import useIsMobile from "hooks/userIsMobile";
import colorList from "layouts/common/colors";
import { useRouter } from "next/router";
import * as React from "react";
import { useMutation, useQuery } from "react-query";
import AuthServices from "services/AuthServices";
import { bSdk } from "services/BackendSDKService";
import { queryClient } from "services/ReactQueryClient";
import Utils from "services/Utils";
import { INotificationInstance } from "types/notification-instance";
import SnackBarUtils from "utils/snackBarUtils";

export default function NotificationMenu() {
  const { user } = useAuth();
  const router = useRouter();
  const isMobile = useIsMobile();
  const lstNotification = useList<INotificationInstance>(
    "notification-instance",
    {
      query: { sort: "CreatedAt", sortOrder: "desc" },
    }
  );
  const unreadNotificationCount = useQuery(
    "unreadNotificationCount",
    async () => {
      let result = await bSdk.cmsQuery("system-users", undefined, {
        route: "/getUnreadNotificationCount",
        method: "get",
        authToken: AuthServices.getToken(),
      });
      return result.data;
    }
  );

  const markNotificationRead = useMutation(async (data: any) => {
    return await bSdk.cmsQuery("notification-instance", data, {
      route: "/markNotificationRead",
      method: "post",
      authToken: AuthServices.getToken(),
    });
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useServerEvent([`notification-update-${user?._id}`], (message) => {
    if (message?.data && message.data._id) {
      queryClient.refetchQueries(["notification-instance"]);
      queryClient.refetchQueries(["unreadNotificationCount"]);
      if (!document.hidden) {
        SnackBarUtils.Show(
          <>
            <h3>{message.data.Title}</h3>
            {message.data.Description}
          </>,
          { persist: true, variant: "info" }
        );
      }
    }
  });

  return (
    <div>
      <IconButton size="large" edge="end" onClick={handleClick} color="inherit">
        <Badge badgeContent={unreadNotificationCount.data}>
          <RIcon name="notifications" />
        </Badge>
      </IconButton>
      <Popover
        id={"simple-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              width: isMobile ? "76vw" : "40vw",
              height: "70vh",
              pr: 0.2,
            },
          },
        }}
      >
        <List sx={{ width: "100%" }}>
          {lstNotification.data &&
            lstNotification.data.map((item) => (
              <ListItem
                key={item._id}
                divider
                sx={{
                  cursor: "pointer",
                  pl: 1,
                  background: item.IsRead
                    ? undefined
                    : lighten(colorList.Green.light, 0.6),
                }}
                disablePadding={true}
                onClick={async () => {
                  if (!item.IsRead) {
                    await markNotificationRead.mutateAsync({
                      NotificationInstanceIDs: [item._id],
                    });
                    queryClient.refetchQueries(["notification-instance"]);
                    unreadNotificationCount.refetch();
                  }
                }}
              >
                <ListItemText disableTypography>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          flexGrow={1}
                          component={"span"}
                        >
                          {item.Title}
                        </Typography>
                        {!item.IsRead && (
                          <Typography
                            style={{ color: "green", marginLeft: "8px" }}
                            fontSize={15}
                            fontWeight={600}
                            component={"span"}
                          >
                            New
                          </Typography>
                        )}
                      </React.Fragment>
                    }
                    secondary={
                      <Box>
                        <SeeMore
                          text={item.Description}
                          modalTitle={
                            <Typography fontWeight={600} fontSize={19}>
                              {item.Title}
                            </Typography>
                          }
                          inModal
                          replaceNewLineWithBR={true}
                          replaceNewLineOnSummary={false}
                          length={280}
                          clickableLine
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "right",
                            pr: 2,
                          }}
                        >
                          <Typography color={"primary"} paddingLeft={2}>
                            {Utils.convertToSystemDateTimeFormate(
                              item.CreatedAt
                            )}
                          </Typography>
                          <Typography color="purple" paddingLeft={1}>
                            {Utils.dateToAgo(item.CreatedAt)} ago
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                </ListItemText>
              </ListItem>
            ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              position: "sticky",
              bottom: 0,
              bgcolor: "white",
              borderTop: `2px solid ${colorList.Blue.light}`,
            }}
          >
            <Button
              variant="text"
              sx={{ pr: 1 }}
              onClick={() => {
                setAnchorEl(null);
                router.push("/notification-instance");
              }}
            >
              View All
            </Button>
          </Box>
        </List>
      </Popover>
    </div>
  );
}
