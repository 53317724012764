import { useEffect } from "react";
import {
  IEventMessage,
  SocketClientFrontend,
} from "services/SocketClientFrontend";
import { getWindow } from "utils/winUtils";
const win = getWindow();

interface ISSEMessage {
  topic: string;
  data?: any;
  message: string;
}

export default function useServerEvent(
  topics: string[],
  cb: (message?: ISSEMessage) => void
) {
  useEffect(() => {
    let res = SocketClientFrontend.addListener(topics, onMessage);

    function onMessage(message: IEventMessage) {
      // console.debug("SSE Message", message);
      if (!topics.includes(message.topic)) return;
      if (message && cb) cb(message);
    }
    return () => {
      res.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
