import {
  Avatar,
  Box,
  IconButton,
  Slide,
  TextField,
  Theme,
  Tooltip,
  useTheme,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import { makeStyles } from "@mui/styles";
import RIcon from "common/misc/RIcon";
import GlobalSearchModal from "common/searchModal/GlobalSearchModal";
import { useAuth } from "contexts/AuthContext";
import { useList } from "hooks/commonQuries";
import useModal from "hooks/useModal";
import useIsMobile from "hooks/userIsMobile";
import Link from "next/link";
import React, { useEffect } from "react";
import Utils from "services/Utils";
import { setIsDrawerOpenOnMobile } from "stores/commonStore";
import { IBookmark } from "types/bookmark";
import { IUser } from "types/user";
import MasterClientSelector from "./MasterClientSelector";
import NotificationMenu from "./NotificationMenu";
import ProfileMenu from "./ProfileMenu";

const useStyles = makeStyles({
  scrollPaper: {
    alignItems: "flex-start",
    paddingTop: "70px",
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MainAppBar() {
  const { user } = useAuth();

  const isMobile = useIsMobile();

  const classes = useStyles();

  const theme = useTheme<Theme>();

  const list = useList<IBookmark>("bookmark", {
    filter: { IsActive: true },
    query: {
      pageSize: 0,
      page: 0,
    },
  });

  const searchModal = useModal((props) => <GlobalSearchModal {...props} />, {
    maxWidth: "sm",
    noTitle: true,
    dialogProps: {
      onClose: () => {
        searchModal.toggle(false);
      },
      classes: {
        scrollPaper: classes.scrollPaper,
      },
      TransitionComponent: Transition,
    },
    onComplete: () => {
      searchModal.toggle(false);
    },
  });

  useEffect(() => {
    const handleClick = (event: any) => {
      if (event.keyCode === 91 && event.ctrlKey) {
        searchModal.toggle(true);
      }
    };

    document.addEventListener("keydown", handleClick);

    // remove the event listener when component umount
    return () => {
      document.removeEventListener("keydown", handleClick);
    };
  }, []);

  function filterBookMarks(user: IUser | null, bookmarkLst?: IBookmark[]) {
    let rolesBookmark = bookmarkLst?.filter((item) => {
      let isMatch: any = false;

      if (user?.IsAdminUser) {
        isMatch = item.ShownOnRoles === user?.Role;
      } else {
        let userAccessRoles = user?.Access?.MasterClients?.map(
          (item) => item.Role
        );
        isMatch = userAccessRoles?.includes(item.ShownOnRoles);
      }

      return isMatch || item.ShownOnRoles === "PUBLIC";
    });

    return rolesBookmark?.sort((a, b) => a.OrderIndex - b.OrderIndex);
  }

  let bookmarkLst = filterBookMarks(user, list?.data);

  return (
    <Toolbar
      id="divToolbar"
      sx={{
        display: "flex",
        alignContent: "center",
        background: "white",
        boxShadow: (theme) => theme.shadows[3],
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isMobile ? (
          <IconButton
            onClick={() => {
              setIsDrawerOpenOnMobile();
            }}
          >
            <RIcon name="menu" />
          </IconButton>
        ) : (
          <></>
          // <TextField
          //   placeholder="Search Anything"
          //   inputProps={{ autoComplete: "off" }}
          //   autoComplete="off"
          //   variant="standard"
          //   onClick={() => {
          //     searchModal.toggle(true);
          //   }}
          // />
        )}
      </Box>
      <div style={{ flexGrow: 1 }}>
        {!isMobile && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              overflow: "auto",
            }}
          >
            {bookmarkLst &&
              bookmarkLst.map((item, index) => (
                <Link href={item.Link} key={index}>
                  <a target="_blank">
                    <Tooltip title={item.Title}>
                      <Avatar
                        style={{
                          cursor: "pointer",
                          marginLeft: "15px",
                          color: Utils.getContrastText(
                            theme,
                            item?.BackgroundColor
                          ),
                          backgroundColor: item.BackgroundColor,
                        }}
                        src={item.Icon}
                        key={item._id}
                      >
                        {item.Icon ? (
                          <RIcon
                            name={item.Icon as any}
                            sx={{
                              color: Utils.getContrastText(
                                theme,
                                item?.BackgroundColor
                              ),
                            }}
                          />
                        ) : (
                          Utils.getFirstCharCap(item.Title)
                        )}
                      </Avatar>
                    </Tooltip>
                  </a>
                </Link>
              ))}
          </div>
        )}
      </div>
      <MasterClientSelector />
      <NotificationMenu />
      &nbsp;
      <ProfileMenu />
      {searchModal.render()}
    </Toolbar>
  );
}
